<template>
  <div>
    <!--begin::details View-->
    <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
      <!--begin::Card header-->
      <div class="card-header cursor-pointer">
        <!--begin::Card title-->
        <div class="card-title m-0">
          <h3 class="fw-bolder m-0">Add Sample</h3>
        </div>
        <!--end::Card title-->
      </div>
      <!--begin::Card header-->

      <!--begin::Card body-->
      <div class="card-body p-9">

        <div class="row mb-3" v-show="success">
          <div class="col-12">
            <div class="alert alert-success" role="alert">
              Successfully added sample!
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-12 col-lg-4 mb-3 mb-lg-0">
            <div class="mb-3 pb-3 border-bottom">
              <h3 class="fw-400">Product Details</h3>
            </div>
            <div class="form-group mb-3">
              <label class="col-form-label required fw-bold fs-6">Product Type</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.product_type_id}" value="SAMPLE" :readonly="true">
              <span class="text-danger" v-if="errors.value && errors.value.product_type_id">{{errors.value && errors.value.product_type_id[0]}}</span>
            </div>
            <div class="form-group mb-3">
              <label class="col-form-label required fw-bold fs-6">Product Code</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.code}" v-model="formData.code" placeholder="Enter product code">
              <span class="text-danger" v-if="errors.value && errors.value.code">{{errors.value && errors.value.code[0]}}</span>
            </div>
            <div class="form-group mb-3">
              <label class="col-form-label required fw-bold fs-6">Name</label>
              <input type="text" class="form-control" :class="{'is-invalid' : errors.value && errors.value.name}" v-model="formData.name" placeholder="Enter name">
              <span class="text-danger" v-if="errors.value && errors.value.name">{{errors.value && errors.value.name[0]}}</span>
            </div>
            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Description</label>
              <textarea class="form-control" :class="{'is-invalid' : errors.value && errors.value.description}" v-model="formData.description" placeholder="Enter description"></textarea>
              <span class="text-danger" v-if="errors.value && errors.value.description">{{errors.value && errors.value.description[0]}}</span>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Weight(kg)</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.weight}" v-model="formData.weight" placeholder="Enter weight">
              <span class="text-danger" v-if="errors.value && errors.value.weight">{{errors.value && errors.value.weight[0]}}</span>
            </div>

            <div class="form-group">
              <label class="col-form-label fw-bold fs-6">Obsolete</label>
              <div class="form-check form-switch">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" v-model="formData.obsolete">
                <label class="form-check-label" for="flexSwitchCheckChecked"></label>
              </div>
            </div>
          </div>


          <div class="col-12 col-lg-4 mb-3 mb-lg-0">
            <div class="mb-3 pb-3 border-bottom">
              <h3 class="fw-400">Packaging</h3>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Labels Per Pack</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.labelsPerPack}" v-model="formData.labelsPerPack" placeholder="Enter labels per pack">
              <span class="text-danger" v-if="errors.value && errors.value.labelsPerPack">{{errors.value && errors.value.labelsPerPack[0]}}</span>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Carton Quantity</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.cartonQty}" v-model="formData.cartonQty" placeholder="Enter carton quantity">
              <span class="text-danger" v-if="errors.value && errors.value.cartonQty">{{errors.value && errors.value.cartonQty[0]}}</span>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Labels Per Carton</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.labelsPerCarton}" v-model="formData.labelsPerCarton" placeholder="Enter labels per carton">
              <span class="text-danger" v-if="errors.value && errors.value.labelsPerCarton">{{errors.value && errors.value.labelsPerCarton[0]}}</span>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Additional Labels</label>
              <input type="number" class="form-control" :class="{'is-invalid' : errors.value && errors.value.additionalLabels}" v-model="formData.additionalLabels" placeholder="Enter additional labels">
              <span class="text-danger" v-if="errors.value && errors.value.additionalLabels">{{errors.value && errors.value.additionalLabels[0]}}</span>
            </div>

          </div>

          <div class="col-12 col-lg-4 mb-3 mb-lg-0">
            <div class="mb-3 pb-3 border-bottom">
              <h3 class="fw-400">Notes</h3>
            </div>

            <div class="form-group mb-3">
              <label class="col-form-label fw-bold fs-6">Note</label>
              <textarea class="form-control" :class="{'is-invalid' : errors.value && errors.value.note}" v-model="formData.note" placeholder="Enter note"></textarea>
              <span class="text-danger" v-if="errors.value && errors.value.note">{{errors.value && errors.value.note[0]}}</span>
            </div>


          </div>

        </div>
      </div>
      <!--end::Card body-->
      <div class="card-footer cursor-pointer">
        <!--begin::Card title-->
        <div class="text-end m-0">
          <button
            type="submit"
            ref="submitButton"
            class="btn btn-lg btn-primary fw-bolder me-3 my-2"
            @click="submitForm"
          >
            <span class="indicator-label">
              Save
            </span>

            <span class="indicator-progress">
              Please wait...
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
        </div>
        <!--end::Card title-->
      </div>
    </div>

  </div>
</template>
<script lang="ts">
import { reactive, ref, onMounted } from 'vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/SamplesEnums.ts";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: 'SampleAdd',
  setup(){

    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Samples",[
        {title : "Samples",to : "/samples/list"},
        {title : "New Sample",to : "/samples/add"}
      ]);
    });

    

    const submitButton = ref<HTMLElement | null>(null);

    const success = ref(false)

    const errors = reactive({
      value : {}
    });

    const formData = reactive({
      code : '',
      name : null,
      description : null,
      weight : null,
      obsolete : false,
      labelsPerPack : 1,
      cartonQty : 0,
      labelsPerCarton : 2,
      additionalLabels : 0,
      note : null
    })

    store.dispatch(Actions.GET_SAMPLES_COUNT).then((r) => {

      formData.code = 'sample-'+(r.count + 1)

    })

    const submitForm = () => {
      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.ADD_SAMPLE,formData).then(() => {

        success.value = true;
        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");
        window.scrollTo(0,0);
        setTimeout(function(){
          router.push('/samples/list')
        },1000)

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    return {
      store,
      submitButton,
      formData,
      submitForm,
      errors,
      success
    }
  }
}
</script>
