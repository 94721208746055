
import { reactive, ref, onMounted } from 'vue'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/SamplesEnums.ts";
import { useRouter } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
export default {
  name: 'SampleAdd',
  setup(){

    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Samples",[
        {title : "Samples",to : "/samples/list"},
        {title : "New Sample",to : "/samples/add"}
      ]);
    });

    

    const submitButton = ref<HTMLElement | null>(null);

    const success = ref(false)

    const errors = reactive({
      value : {}
    });

    const formData = reactive({
      code : '',
      name : null,
      description : null,
      weight : null,
      obsolete : false,
      labelsPerPack : 1,
      cartonQty : 0,
      labelsPerCarton : 2,
      additionalLabels : 0,
      note : null
    })

    store.dispatch(Actions.GET_SAMPLES_COUNT).then((r) => {

      formData.code = 'sample-'+(r.count + 1)

    })

    const submitForm = () => {
      if(submitButton.value){
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      store.dispatch(Actions.ADD_SAMPLE,formData).then(() => {

        success.value = true;
        errors.value = [];
        submitButton.value?.removeAttribute("data-kt-indicator");
        window.scrollTo(0,0);
        setTimeout(function(){
          router.push('/samples/list')
        },1000)

      }).catch((response) => {
        setTimeout(function(){
          const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
          input.focus();
        },100)

        errors.value = response.data.errors;

        submitButton.value?.removeAttribute("data-kt-indicator");

      })
    }

    return {
      store,
      submitButton,
      formData,
      submitForm,
      errors,
      success
    }
  }
}
